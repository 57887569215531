@use "sass:math"; @import "abstract";
.financingProductError {
  text-align: center;

  &__icon {
    margin: 0 auto;
  }

  &__title {
    margin-top: 20px;
  }

  &__subtitle {
    margin-top: 8px;
  }

  &__actions {
    margin-top: 32px;
  }
}
