@use "sass:math"; @import "abstract";
.custom-leasing-radio {

  &__single {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__multiple {
    align-items: start;
    display: grid;
    grid-template-areas:
      "custom-leasing-radio__title custom-leasing-radio__logos"
      "custom-leasing-radio__subtitle custom-leasing-radio__logos"
      "custom-leasing-radio__list custom-leasing-radio__lessors-list";
    grid-template-columns: 2fr 1fr;
  }

  &__title {
    grid-area: custom-leasing-radio__title;
  }

  &__subtitle {
    grid-area: custom-leasing-radio__subtitle;
  }

  &__logos {
    display: flex;
    flex-direction: row-reverse;
    grid-area: custom-leasing-radio__logos;
    justify-content: flex-end;

    li {

      &:not(:first-child) {
        margin-right: -5px;
      }
    }
  }

  &__icon {
    align-items: center;
    background: $white;
    border-radius: 50%;
    display: flex;
    filter: drop-shadow(0 0 24px rgba(40, 44, 46, 0.12));
    height: 40px;
    justify-content: center;
    padding: 8px;
    position: relative;
    width: 40px;
  }

  &__list {
    grid-area: custom-leasing-radio__list;
  }
}

@include mq($until: tablet) {

  .custom-leasing-radio {

    &__multiple {
      grid-template-areas:
        "custom-leasing-radio__title"
        "custom-leasing-radio__subtitle"
        "custom-leasing-radio__logos"
        "custom-leasing-radio__list";
      grid-template-columns: 1fr;
    }

    &__logos {
      justify-content: start;
      margin-top: 16px;
    }
  }
}
