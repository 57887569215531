@use "sass:math"; @import "abstract";
.customizedLeasingFormFields {
  display: grid;
  grid-gap: rem(8);
  grid-template-areas:
    'customized_form_field_radio_group customized_form_field_radio_group'
    'customized_form_field_contacts_title customized_form_field_contacts_title'
    'customized_form_field_phone customized_form_field_email'
    'customized_form_field_request_comment customized_form_field_request_comment'
    'customized_form_field_location customized_form_field_location'
    'customized_form_field_agreement customized_form_field_agreement'
    '. customized_form_field_submit_button';
  grid-template-columns: repeat(2, 1fr);
  position: relative;

  .customizedLeasingFormFieldSubmitButton {
    display: grid;
    grid-area: customized_form_field_submit_button;
    justify-items: end;
    margin-top: rem(16);

    button {
      margin-top: rem(16);
    }
  }

  .customizedLeasingFormFieldContactsTitle {
    grid-area: customized_form_field_contacts_title;
    margin-bottom: rem(16);
    width: 100%;
  }

  .customizedLeasingFormFieldRadioGroup {
    grid-area: customized_form_field_radio_group;
    margin-bottom: rem(32);
    width: 100%;

    &__radioItem {
      align-items: center;
      border: 2px solid $lightGray;
      border-radius: rem(8);
      flex-direction: row;
      padding: 16px 24px;
      will-change: background-color, border;

      .custom-radio__label {
        width: 100%;
      }

      transition: {
        duration: 0.2s;
        property: background-color, border;
        timing-function: $sharp;
      }

      &:not(:first-child) {
        margin-top: rem(16);
      }

      &:hover,
      &:focus {
        border-color: $green;

        input:not(:checked) {

          ~ .custom-radio__checkmark {
            background-color: $lightGray;
            border-color: $lightGray;
          }
        }
      }

      &_checked {
        background-color: $lightGreen;
        border-color: $lightGreen;
      }
    }
  }

  .customizedLeasingFormFieldPhone {
    grid-area: customized_form_field_phone;
  }

  .customizedLeasingFormFieldEmail {
    grid-area: customized_form_field_email;
  }

  .customizedLeasingFormFieldComment {
    grid-area: customized_form_field_request_comment;
  }

  .customizedLeasingFormFieldLocation {
    grid-area: customized_form_field_location;

    .input-wrapper {
      margin-bottom: 0;
    }
  }

  .customizedLeasingFormFieldAgreement {
    grid-area: customized_form_field_agreement;
    width: 100%;

    &__checkbox {

      .checkbox-container__label {
        display: block;
      }
    }

    &__checkbox-error {
      margin-top: rem(8);
    }

    &__link {
      color: $color-text-accent;
    }
  }

  .customized-form__requiring-fields-sign {
    grid-area: customized_form__requiring_fields_sign;
  }
}

@include mq($until: desktop) {

  .customizedLeasingFormFields {
    grid-template-areas:
      'customized_form_field_radio_group customized_form_field_radio_group'
      'customized_form_field_contacts_title customized_form_field_contacts_title'
      'customized_form_field_phone customized_form_field_email'
      'customized_form_field_request_comment customized_form_field_request_comment'
      'customized_form_field_location customized_form_field_location'
      'customized_form_field_agreement customized_form_field_agreement'
      'customized_form_field_submit_button customized_form_field_submit_button';
    grid-template-columns: repeat(2, 1fr);

    .customizedLeasingFormFieldContactsTitle {
      margin-bottom: rem(8);
    }

    .customizedLeasingFormFieldRadioGroup {

      &__radioItem {

        .custom-radio__checkmark {
          align-self: flex-start;
          position: relative;
          top: rem(7);
        }
      }
    }

    .customizedLeasingFormFieldSubmitButton {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}

@include mq($until: tablet) {

  .customizedLeasingFormFields {
    grid-template-areas:
      'customized_form_field_radio_group'
      'customized_form_field_contacts_title'
      'customized_form_field_phone'
      'customized_form_field_email'
      'customized_form_field_request_comment'
      'customized_form_field_location'
      'customized_form_field_agreement'
      'customized_form_field_submit_button';
    grid-template-columns: 1fr;

    .customizedLeasingFormFieldSubmitButton {
      margin-top: 0;

      button {
        margin-top: rem(32);
      }
    }

    .customizedLeasingFormFieldRadioGroup {

      &__radioItem {
        padding: 16px;
      }
    }
  }
}
