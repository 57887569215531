@use "sass:math"; @import "abstract";
.bankAccountsDropdown {
  width: 100%;

  &__menu {

    &-header {
      align-items: center;
      display: flex;
      gap: 8px;
    }
  }

  &__option {

    &--account, &--default {
      color: $paleBlack;
      margin-left: 6px;
    }
  }
}
