@use "sass:math"; @import "abstract";
.navSuccessNew {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
}

.navSuccessNewItem {
  background: $color-base-secondary;
  border-radius: 4px;
  color: $color-text-primary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 24px;
  position: relative;

  &__title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    text-align: left;
    white-space:pre-wrap;
    width: 100%;
  }

  &__image {
    height: 80px;
    text-align: left;
    width: 80px;
  }

  &__arrow {
    align-items: flex-end;
    align-self: flex-end;
    display: flex;
  }

  &__agent {
    background-color: $orange-50;
    border: 1px solid $orange-100;
  }
}

@include mq($until: tablet) {

  .navSuccessNew {
    grid-template-columns: 1fr;
  }

  .navSuccessNewItem {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 88px;
    padding: 16px;

    &__icon {
      height: 80px;
      width: 80px;
    }

    &__title {
      max-width: 155px;
    }

    &__arrowIcon {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }
}

