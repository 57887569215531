@use "sass:math"; @import "abstract";
.loanProducts {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

@include mq($until: desktop) {

  .loanProducts {
    flex-direction: column;
  }
}
