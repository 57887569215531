@use "sass:math"; @import "abstract";
.loanProductCalc {

  &__title {
    margin-bottom: 32px;
  }
}

.loanProductCalcWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 40px;

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px;
  }
}

.loanProductCalcPs {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__item {
    color: $color-text-secondary;
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    gap: 4px;
  }
}

.loanProductCalcForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 40px;
  }
}

.loanProductCalcActions {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: flex-end;
}

.loanProductCalcResultError {

  &__message {
    margin-top: 16px;
  }
}

@include mq($until: desktop) {

  .loanProductCalcActions {
    justify-content: flex-start;
  }
}

@include mq($until: tablet) {

  .loanProductCalcWrap {
    gap: 24px;
  }

  .loanProductCalc {

    &__title {
      margin-bottom: 24px;
    }
  }

  .loanProductCalcActions {
    flex-direction: column;
  }
}
