@use "sass:math"; @import "abstract";
.financingProductsTab {
  align-items: center;
  display: flex;
  gap: 10px;

  &__icon {
    background-size: contain;
    height: 24px;
    width: 24px;
  }

  &__text {
    font-family: $fontFamilyBase;
    font-size: 18px;
    font-weight: 600;
  }
}

@include mq($until: tablet) {

  .financingProductsTab {
    align-items: unset;

    &__icon {
      height: 20px;
      width: 20px;
    }

    &__text {
      font-size: 16px;
    }
  }
}
