@use "sass:math"; @import "abstract";
.loanForm {

  &__title {
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__selectContainer {
    position: relative;
  }

  &__slider {
    margin-bottom: 10px;

    label {
      font-size: 14px;
    }
  }

  &__switchGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__resetButton {
    margin: unset;
    width: fit-content;
  }
}

@include mq($until: tablet) {

  .loanForm {

    &__selectContainer {

      .multiSelect__menu.rs-picker-menu.rs-picker-check-menu {
        bottom: 60px !important;
      }
    }
  }
}
