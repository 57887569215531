@use "sass:math"; @import "abstract";
.loanProductConsultFormContent {
  max-width: 470px;

  &__title {
    margin-bottom: 16px;
  }
}

.loanProductConsultFormActions {
  display: flex;
  gap: 24px;
  margin-top: 40px;
}

.loanProductConsultFormFields {
  margin-bottom: 16px;
  margin-top: 24px;
}
