@use "sass:math"; @import "abstract";
.financingQuestions {
  align-items: center;
  background: $white;
  display: grid;
  gap: 16px;
  grid-template-areas: "questions_title questions_button" "questions_description questions_button";
  justify-content: space-between;
  margin-top: 56px;
  padding: 56px;

  &__title {
    grid-area: questions_title;
  }

  &__description {
    grid-area: questions_description;
  }

  &__button {
    grid-area: questions_button;
  }
}

@include mq($until: tablet) {

  .financingQuestions {
    display: block;
    margin-top: 24px;
    padding: 24px 16px;
    text-align: center;

    &__description {
      padding: 16px 0 24px;
    }
  }
}
