@use "sass:math"; @import "abstract";
.customized-form {
  position: relative;

  &__description {
    margin-bottom: 24px;
  }

  &__terms {
    text-align: center;
  }

  &__error {
    margin-bottom: -8px;
  }

  &__control {
    margin-bottom: 8px;
  }

  &__custom-button {

  }

  &__custom-actions {
    display: flex;
    justify-content: center;
    margin: 32px 0;
  }

  &__submit-button-wrapper {
    width: fit-content;

    @include mq($until: tablet) {
      margin-top: 32px;
    }

    .button {
      margin-top: 0 !important;
    }
  }
}
