@use "sass:math"; @import "abstract";
.dealAgreementModal {

  .wizard-form_2 {
    max-width: 396px;
  }

  &__title {
    margin-bottom: 16px;

    &_enter-code {
      max-width: 350px;
    }
  }

  &__datePicker {
    grid-area: datePicker;
  }

  &__agreed {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  &__notice {
    font-size: rem(16);
    margin-bottom: rem(18);
  }

  &__downloadButton {
    width: 100%;
  }

  &__disagreement {
    margin-bottom: 16px;
  }

  &__notice {
    margin-bottom: 16px;
  }

  &__bottomLine {
    margin-top: 8px;
  }
}

.dealAgreementModalRequired {

  &__content {
    margin-bottom: 16px;
  }
}

.dealAgreementModalDropdown {
  grid-area: dropdown;
  margin-bottom: 16px;
}

.dealAgreementModalWrapper {
  display: grid;
  gap: 16px;
  grid-template-areas:
    "dropdown"
    "content"
    "info";
  grid-template-columns: 1fr;

  &.withDatePicker {
    grid-template-areas:
      "dropdown dropdown"
      "content content"
      "datePicker info";
    grid-template-columns: 1fr 1fr;
  }
}

.dealAgreementModalItem {
  display: flex;
  grid-area: info;
  justify-content: space-between;

  &__infoFail {
    padding-top: 6px;
  }

  &__info-description {
    padding-top: 4px;
  }
}

.dealAgreementModalContent {
  background-color: $lightGray;
  background-image: linear-gradient(to top, rgba($white, 0.3), $lightGray);
  grid-area: content;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  &__document {
    border: 0;
  }
}

@include mq($from: desktop) {

  .dealAgreementModal {

    .wizard-form_1 {
      margin-left: rem(16);
      margin-right: rem(16);
      width: rem(769);
    }
  }
}


@include mq($from: tablet) {

  .dealAgreementModal {

    &__downloadButton {
      margin-left: auto;
    }
  }

  .dealAgreementModalContent {
    height: rem(412);
    margin-bottom: 16px;
    padding: 40px;

    &__document {
      height: 100%;
      width: 100%;
    }
  }

  .dealAgreementModalItem {

    &__info {
      display: none;
    }
  }
}

@include mq($from: tablet, $until: desktop) {

  .dealAgreementModal {

    .wizard-form_1 {
      width: rem(596);
    }
  }
}

@include mq($until: tablet) {

  .dealAgreementModal {

    .text-with-icon {
      white-space: initial;
    }

    &__title {
      margin-bottom: 24px;
    }

    &__agreed {
      margin-bottom: 24px;
    }

    &__buttom {
      white-space: normal;
      width: 100%;
    }

    &__buttomAccepting {
      height: auto;
      min-width: mRem(218);
      white-space: normal;
      width: auto;
    }


    &__downloadButton {
      height: 34px;
      margin: unset;
      min-height: unset;
      min-width: unset;
      padding: 10px 24px 11px;
      width: fit-content;
    }

    &__disagreement {
      margin-top: 8px;
    }

    &__notice {
      margin-top: 24px;
    }
  }

  .dealAgreementModalWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  }

  .dealAgreementModalContent {
    height: mRem(228);
    padding: 24px 16px 16px;

    &__document {
      height: 250%;
      transform: scale(0.4);
      transform-origin: top left;
      width: 250%;
    }
  }

  .dealAgreementModalItem {
    order: -1;

    &__info {
      display: flex;
      flex-direction: column;
      padding-left: 28px;
      position: relative;

      &::before {
        background-image: url('/assets/icons/flat/document.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        display: block;
        height: 20px;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 17px;
      }

      & > div {
        border-top: 1px solid $paleBlack;
      }

      & > span:first-child {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & > span:not(:first-child) {
        color: $paleBlack;
      }
    }

    &__infoFail {

      &::before {
        background-image: url('/assets/icons/flat/filled/error.svg');
        opacity: 1;
        top: 6px;
      }
    }
  }
}
