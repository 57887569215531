@use "sass:math"; @import "abstract";
.noSuitableProducts {
  align-items: center;
  display: flex;
  flex-flow: column;
  gap: 24px;
  padding: 40px 0 32px;
  text-align: center;

  &__icon {
    font-size: 120px;
    margin-bottom: -4px;
  }
}
