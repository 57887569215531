@use "sass:math"; @import "abstract";
.loanProductsHeader {
  color: $green;
  display: inline-flex;
  margin: 24px 0 16px;

  .userRegionSelectPickerDesktop,
  .userRegionSelectPickerMobile {

    &__label {
      color: $black;
    }

    &__button {
      font-weight: inherit;
      text-decoration: underline $lightGreen;
      text-underline-offset: 4px;
    }
  }
}

@include mq($until: tablet) {

  .loanProductsHeader {
    margin: 16px 0;
  }
}
