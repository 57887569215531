@use "sass:math"; @import "abstract";
.loanProductsList {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;

  &__compatibleLabel {
    margin: 8px 0 16px;
  }

  &__containerOfProducts {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  &__additionalLeasing {
    margin-top: 40px;

    h3 {
      margin-bottom: 24px;
    }
  }

  &__othersLabel {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    &__text {
      margin-top: 24px;
    }
  }
}
