@use "sass:math"; @import "abstract";
.loanProductHeader {
  display: flex;
  gap: 24px;
  width: 100%;

  &__title {
    max-width: 560px;

    &--withBadge {
      padding-left: 80px;
    }
  }

  &__logo {
    display: flex;
    gap: 8px;
    height: 40px;
    margin-left: auto;
  }

  &__brand {
    height: 40px;
    width: auto;
  }
}

@include mq($until: tablet) {

  .loanProductHeader {
    align-items: flex-start;
    flex-direction: row-reverse;
    gap: 16px;
    justify-content: flex-end;

    &__title {
      max-width: 100%;

      &--withBadge {
        padding-left: 0;
        padding-right: 60px;
      }
    }

    &__logo {
      margin-left: 0;
    }

    &__brand {
      height: 40px;
      min-width: 40px;
      width: 40px;
    }
  }
}
