@use "sass:math"; @import "abstract";
.loanProductCollapsing {
  background-color: $color-base-primary;
  padding: 40px;
}

@include mq($until: tablet) {

  .loanProductCollapsing {
    padding: 32px 16px 16px;
  }
}
