@use "sass:math"; @import "abstract";
.loanProductTooltip {

  &__content {
    align-items: center;
    display: flex;
  }

  &__icon {
    color: $color-control-bg-accent;
  }

  &__text {
    white-space: pre-wrap;

    a {
      color: $color-control-bg-accent;
    }
  }
}
