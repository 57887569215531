@use "sass:math"; @import "abstract";
.loanProductFooter {
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 32px;

  &__actions {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;
  }

  &__actionsIcon {
    margin-left: 4px;
  }

  &__link {
    align-items: center;
    color: $color-text-primary;
    display: flex;
    font-family: $fontFamilyBase;
    font-weight: 600;
    gap: 8px;
  }

  &__consult {
    align-items: center;
    color: black;
    display: flex;
    gap: 10px;
  }

  &__consultArrow {
    background-image: url('/assets/icons/all_icons/arrow-right-2.svg');
    height: 24px;
    width: 24px;
  }

  &__brand {
    height: 40px;
    width: 200px;
  }

  &__collapsingTrigger {
    gap: 10px;
  }
}

@include mq($until: desktop) {

  .loanProductFooter {
    align-items: flex-start;
    flex-direction: column-reverse;

    &__logo {
      align-self: flex-start;
      margin: -16px 0 32px -22px;
    }
  }
}

@include mq($until: tablet) {

  .loanProductFooter {
    margin-top: 16px;

    &__actions {
      display: flex;
      flex-flow: column;
      gap: 12px;
      text-align: center;
      width: 100%;

      a {
        width: 100%;
      }
    }

    &__link {
      width: 100%;
    }
  }
}

@include mq($from: tablet, $until: desktop) {

  .loanProductFooter {

    &__actions {
      flex-wrap: wrap;
    }

    &__buttonWidth {
      width: fit-content;
    }
  }
}
