@use "sass:math"; @import "abstract";
.MFOCalculatorTerms {

  &__mobile {
    display: none;
  }

  &__container {
    background: $color-control-bg-secondary;
    border-radius: 16px;
    display: flex;
    overflow: hidden;
  }

  &__hideContent {
    display: none !important;
  }

  &__standard,
  &__special {
    flex: 1;
    min-width: 0;
    padding: 32px 32px 36px;
  }

  &__special {
    background: $color-control-bg-accent;
    border-radius: 16px;
    color: $color-text-inverted;
  }

  &__specialForAgribusiness {
    color: $color-control-bg-yellow-status;
    font-weight: bold;
  }

  &__fromTop10 {
    color: $color-text-tertiary;
    font-weight: bold;
  }

  &__specialForAgribusiness,
  &__fromTop10 {
    align-items: flex-start;
    display: flex;
    margin-top: 8px;
  }

  &__standardTermsTitle,
  &__specialImage {
    height: 64px;
  }

  &__terms {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  &__term {
    border-bottom: 1px solid $color-control-border-primary;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
    width: 100%;

    &--economy {
      border-bottom: 0;
      margin-top: 8px;
      padding-bottom: 0;
    }

    &--repaymentProcedure {
      height: 96px;
    }
  }

  &__questionIcon {
    margin-left: 8px;
    position: relative;
    top: 2px;
  }

  &__standard {

    .MFOCalculatorTerms__label {
      color: $color-text-secondary;
    }

    .MFOCalculatorTerms__economyValue {
      color: $color-text-disabled;
    }
  }

  &__special {

    .MFOCalculatorTerms__label {
      color: $color-status-success-default;
    }

    .MFOCalculatorTerms__label--accent {
      color: $color-control-bg-yellow-status;
      font-weight: 700;
    }

    .MFOCalculatorTerms__economyValue {
      color: $color-text-inverted;
    }
  }

  &__economyValue {
    display: flex;
  }

  &__tooltip-position {
    margin-left: 4px;
  }
}

.MFOCalculatorTermsShowAllParams {
  align-items: center;
  display: flex;
  justify-content: center;

  &__button {
    margin-left: 0;
    margin-right: 0;
  }

  &__icon {
    color: $color-control-bg-accent;
  }
}

@include mq($until: desktop) {

  .MFOCalculatorTerms {

    &__header {
      height: 120px;
    }

    &__fromTop10,
    &__specialForAgribusiness {
      height: 48px;
    }
  }
}

@include mq($until: tablet) {

  .MFOCalculatorTerms {

    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }

    &__header {
      height: 104px;
    }

    &__standard,
    &__special {
      padding: 32px 16px;
    }

    &__standardTermsTitle,
    &__specialImage {
      height: 44px;
    }

    &__fromTop10,
    &__specialForAgribusiness {
      height: 52px;
    }

    &__terms {
      gap: 8px;
      margin-top: 16px;
    }

    &__term {

      &--economy {
        border-bottom: 1px solid $color-control-border-primary;
        padding-bottom: 8px;
      }

      &--repaymentProcedure {
        height: 112px;
      }
    }

    &__questionIconWrapper {
      position: relative;
    }

    &__questionIcon {
      margin-left: 8px;
      position: absolute;
      top: -2px;
    }
  }
}

@include mq($until: mobile) {

  .MFOCalculatorTerms {

    &__term {

      &--repaymentProcedure {
        height: 130px;
      }
    }
  }
}
