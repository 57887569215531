@use "sass:math"; @import "abstract";
.loanProductOrderMethodCommunication {
  gap: 40px;

  &__card {
    max-width: 269px;
  }
}


@include mq($until: tablet) {

  .loanProductOrderMethodCommunication {
    gap: 24px;
  }
}
