@use "sass:math"; @import "abstract";
.loanProductAdditionalValues {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-top: 16px;
}

.loanProductAdditionalValue {
  align-items: center;
  background-color: $color-base-primary;
  border: 1px solid $color-control-border-primary;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  justify-content: center;
  padding: 8px;

  &__icon {
    color: $color-control-bg-accent;
  }

  &:nth-child(1) {
    width: 378px;
  }

  &:nth-child(2) {
    flex: 1;
  }
}

@include mq($until: tablet) {

  .loanProductAdditionalValues {
    flex-direction: column;
  }

  .loanProductAdditionalValue {

    &:nth-child(1) {
      width: 100%;
    }
  }
}
