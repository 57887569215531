@use "sass:math"; @import "abstract";
.multipleLeasing {
  align-items: center;
  display: grid;
  grid-column-gap: 40px;
  grid-template-areas:
    'multiple_leasing_icons multiple_leasing_title multiple_leasing_button'
    'multiple_leasing_icons multiple_leasing_text multiple_leasing_button';
  grid-template-columns: 180px auto 1fr;
  padding: 56px 104px;

  &_clickable {
    cursor: pointer;
  }

  &_clickable:hover &__title {
    color: $color-text-accent;
  }

  &__icons {
    flex-wrap: wrap;
    grid-area: multiple_leasing_icons;
    justify-content: end;
    margin: 5px 16px 0 0;

    li {
      height: 56px;
      margin-top: -5px;
      padding: 10px;
      width: 56px;

      &:not(:first-child) {
        margin-right: -20px;
      }
    }
  }

  &__title {
    grid-area: multiple_leasing_title;
    transition: color 0.2s $sharp;
    will-change: color;
  }

  &__text {
    grid-area: multiple_leasing_text;
  }

  &__button {
    grid-area: multiple_leasing_button;
  }
}

@include mq($until: desktop) {

  .multipleLeasing {
    grid-template-areas:
      'multiple_leasing_icons multiple_leasing_button'
      'multiple_leasing_title multiple_leasing_button'
      'multiple_leasing_text multiple_leasing_button';
    grid-template-columns: 1fr auto;
    padding: 48px 32px;

    &__icons {
      justify-content: start;

      li {
        height: 52px;
        margin-top: 0;
        width: 52px;

        &:not(:first-child) {
          margin-right: -14px;
        }
      }
    }

    &__title {
      margin-top: 16px;
    }
  }
}

@include mq($until: tablet) {

  .multipleLeasing {
    grid-template-areas:
      'multiple_leasing_icons'
      'multiple_leasing_title'
      'multiple_leasing_text'
      'multiple_leasing_button';
    grid-template-columns: auto;
    padding: 32px 24px;

    &__icons {
      justify-content: center;
      margin: 0;

      li {
        height: 60px;
        width: 60px;

        &:not(:first-child) {
          margin-right: -20px;
        }
      }
    }

    &__title,
    &__button {
      margin-top: 24px;
    }
  }
}
