@use "sass:math"; @import "abstract";
.leasingOffers {
  margin-top: 24px;

  &__list {
    display: grid;
    gap: 16px;
  }
}

@include mq($until: tablet) {

  .leasingOffers {
    margin-top: 16px;
  }
}
