@use "sass:math"; @import "abstract";
.multiLeasingCard {
  background-color: $color-base-primary;
  padding: 32px 40px;

  &__header {
    align-items: flex-start;
    display: flex;
    gap: 24px;
    justify-content: space-between;
  }

  &__description {
    color: $color-text-secondary;
    margin-top: 12px;
  }

  &__logos {
    justify-content: start;

    .leasing-partners-short-logos__item {
      height: 40px;
      margin-top: 0;
      padding: 7px;
      width: 40px;

      &:not(:first-child) {
        margin-right: -8px;
        z-index: auto;
      }
    }
  }

  &__actions {
    margin-top: 24px;
  }
}

@include mq($until: tablet) {

  .multiLeasingCard {
    padding: 16px;

    &__header {
      flex-direction: column-reverse;
    }

    &__actions {
      margin-top: 16px;
    }
  }
}
