@use "sass:math"; @import "abstract";
.leasingOffer {
  align-items: stretch;
  display: grid;
  gap: 32px 48px;
  grid-template-areas:
    'leasing_icon leasing_benefit'
    'leasing_icon leasing_link';
  grid-template-columns: 350px 1fr;
  grid-template-rows: 1fr 1fr;
  padding-right: 48px;

  &__icon {
    border: solid $color-control-border-primary;
    border-width: 0 1px 0 0;
    display: flex;
    flex-direction: column;
    grid-area: leasing_icon;
    margin: 24px 0;
    padding: 0 24px;
  }

  &__benefit {
    align-self: flex-end;
    grid-area: leasing_benefit;
    max-width: 772px;
    padding-top: 36px;
    white-space: pre-wrap;
  }

  &__imageWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 128px;
  }

  &__link {
    align-self: flex-start;
    grid-area: leasing_link;
    padding-bottom: 36px;
  }

  &__button {
    width: 250px;
  }
}

@include mq($until: desktop) {

  .leasingOffer {
    grid-template-rows: auto;
  }
}

@include mq($until: tablet) {

  .leasingOffer {
    gap: 0;
    grid-template-areas:
      'leasing_icon leasing_icon'
      'leasing_benefit leasing_benefit'
      'leasing_link leasing_link';
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 16px 16px 24px;

    &__icon {
      border-width: 0 0 1px;
      margin: 0;
      padding: 0 0 16px;
    }

    &__imageWrapper {
      min-height: 108px;
    }

    &__benefit {
      align-self: auto;
      margin-top: 16px;
      padding-top: 0;
    }

    &__link {
      align-self: auto;
      margin-top: 24px;
      padding-bottom: 0;
    }

    &__button {
      width: auto;
    }
  }
}
