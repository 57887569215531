@use "sass:math"; @import "abstract";
.loanProductConditions {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 24px;
  width: 100%;
}

.loanProductCondition {
  background-color: $color-base-primary;
  border: 1px solid $color-control-border-primary;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  padding: 12px;

  &__label {
    color: $color-text-secondary;
  }

  &__value {
    font-family: $fontFamilyBase;
  }

  &:nth-child(1) {
    width: 158px;
  }

  &:nth-child(2) {
    width: 212px;
  }

  &:nth-child(3) {
    width: 178px;
  }

  &:nth-child(4) {
    width: 180px;
  }
}

@include mq($until: desktop) {

  .loanProductCondition {

    &__value {
      font-size: 14px;
    }

    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      width: unset;
    }
  }
}

@include mq($until: tablet) {

  .loanProductConditions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
  }

  .loanProductCondition {
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0;
    flex-direction: row;
    gap: 4px;
    padding: 0;

    &__label {
      width: 50%;
    }

    &__value {
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      width: 50%;
    }
  }
}
