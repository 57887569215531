@use "sass:math"; @import "abstract";
.financingReviews {
  background-color: inherit;
  margin-bottom: 40px;
  padding-bottom: 0;

  &__slide {
    background: $white;
    min-height: auto;
    padding: 40px;
  }
}

@include mq($until: desktop) {

  .financingReviews {

    &__slider {
      padding: 0;
    }
  }
}

@include mq($until: tablet) {

  .financingReviews {
    margin: 0 -16px;
    padding-top: 24px;

    &__title {
      padding: 0 16px;
    }

    &__slide {
      padding: 16px;
    }

    .reviews__arrow--left {
      left: 5px;
    }

    .reviews__arrow--right {
      right: 5px;
    }
  }
}
