@use "sass:math"; @import "abstract";
.loanProductsEmpty {
  background-color: $color-base-primary;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;

  &__steps {
    list-style: inside;

    li {
      color: $color-text-tertiary;
    }
  }

  &__actions {

    button {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@include mq($until: tablet) {

  .loanProductsEmpty {
    gap: 16px;
    padding: 16px;
  }
}
