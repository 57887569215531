@use "sass:math"; @import "abstract";
.financingOfferModal {

  &__container {

    &--advancePayment {
      padding: 56px 40px 40px;

      .customized-form-modal {
        max-width: 400px;
      }

      .modal-content__close-button {
        right: 40px;
        top: 24px;
      }

      .input-wrapper,
      .dropdown-view {
        width: 100%;
      }

      .customized-form {
        display: flex;
        flex-flow: row wrap;

        .financingOfferModal__backButton {
          justify-self: flex-end;
          margin: 24px 0 0 auto;
          max-width: 142px;
        }

        .financingOfferModal__submitButton[type='submit'] {
          margin: 24px 0 0 8px;
        }

        .financingOfferModal__infoText {
          align-items: flex-start;
          gap: 12px;
          margin-top: 12px;
          opacity: 1;
        }
      }
    }
  }

  &__policyAgreementIcon {
    color: $color-base-accent;
  }

  &__policyAgreementLink {
    color: $color-text-accent;
  }
}

@include mq($until: desktop) {

  .financingOfferModal {

    &__container {
      width: 420px;

      &--advancePayment {
        width: 480px;
      }
    }
  }
}

@include mq($until: tablet) {

  .modal-content-wrapper__financingOfferModalWrapper {
    width: 100%;
  }

  .financingOfferModal {

    &__container {
      width: 100%;

      &--advancePayment {
        padding: 20px 16px 16px;
        width: 100vw;

        .customized-form-modal {
          flex: 1;
          max-width: unset;
        }

        .modal-content__close-button {
          color: $color-text-tertiary;
          right: 16px;
          top: 16px;
        }

        .wizard-form {
          height: 100%;
        }

        .customized-form {
          flex-direction: column;
          height: 100%;

          .customized-form__title {
            margin-bottom: 24px;
            padding-bottom: 24px;
            position: relative;

            &::after {
              background-color: $color-control-border-primary;
              bottom: 0;
              content: '';
              height: 1px;
              left: -16px;
              position: absolute;
              right: -16px;
            }
          }

          .text-input-view__require-icon {
            margin: 0;
          }

          .financingOfferModal__infoText {
            margin-top: 20px;
          }

          .financingOfferModal__backButton {
            display: none;
          }

          .financingOfferModal__submitButton[type='submit'] {
            display: flex;
            flex: unset;
            margin: auto 0 0;
          }
        }
      }
    }
  }
}
