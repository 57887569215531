@use "sass:math"; @import "abstract";
.textWithConsultAction {

  &__link {

    &:hover {
      cursor: pointer;
    }
  }
}
