@use "sass:math"; @import "abstract";
.loanProductOrderForm {

  &__title {
    margin-bottom: 40px;
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;
    justify-content: space-between;

    @include mq($until: desktop) {
      flex-direction: column;
    }
  }
}

.loanProductOrderFormInfo {
  display: flex;
  flex-direction: column;

  &__warning {
    align-items: flex-start;
    color: $color-text-tertiary;
  }
}

.loanProductOrderFormCalc {
  margin-top: 24px;

  &__heading {
    color: $color-text-tertiary;
  }

  &__action {
    margin-top: 16px;
  }

  &__actionIcon {
    left: -8px;
    position: relative;
  }
}

.loanProductOrderFormFields {
  width: 380px;

  &__infoText {
    align-items: flex-start;
    margin-top: 16px;
  }

  &__infoText {
    opacity: 1;
  }

  .customized-form__title {
    display: none;
  }

  .customized-form__actions {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: flex-start;
    margin-top: 40px;

    @include mq($until: desktop) {
      flex-direction: column;
      gap: 16px;
    }
  }
}

.loanProductOrderFormActions {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  margin-top: 40px;
}

.loanProductOrderFormFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@include mq($until: desktop) {

  .loanProductOrderFormFields {
    width: 100%;
  }
}

@include mq($until: tablet) {

  .loanProductOrderForm {

    &__title {
      margin-bottom: 24px;
    }

    &__row {
      gap: 24px;
    }
  }

  .loanProductOrderFormFields {
    max-width: 639px;
  }
}
