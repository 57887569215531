@use "sass:math"; @import "abstract";
.loanProduct {
  background-color: $color-base-primary;
  position: relative;
  width: 100%;

  &__wrapper {
    min-height: 276px;
    position: relative;
  }

  &--active {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  }

  &--accent {
    background-color: $color-status-success-default-fill;
    outline: 1px solid $color-control-border-accent;

    .loanProduct--active {
      box-shadow: 0 0 30px 0 rgba(101, 165, 126, 0.5);
    }
  }

  // Костыльный блок для учета высоты фиксированной шапки при скролле

  &__anchor {
    display: block;
    position: absolute;
    top: -100px;
    visibility: hidden;
  }

  &__data {
    padding: 32px 40px;
  }

  &__farmerChoice {
    left: 8px;
    position: absolute;
    top: -4px;
  }
}

@include mq($until: desktop) {

  .loanProduct {

    &__wrapper {
      grid-template-columns: 300px 1fr;
    }

    &__data {
      padding: 32px 32px 32px 48px;
    }
  }
}

@include mq($until: tablet) {

  .loanProduct {

    &__wrapper {
      grid-template-columns: auto;
      min-height: auto;
    }

    &__data {
      padding: 16px;
    }

    &__farmerChoice {
      left: auto;
      right: 0;
      top: 0;
    }
  }
}

@include mq($until: tablet) {

  .loanProduct {
    border-radius: 8px;
  }
}
