@use "sass:math"; @import "abstract";
.communicationMethodSelector {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__image {
    height: 23px;
    margin-bottom: 25px;
    width: 23px;
  }

  &__container {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 100%;
  }

  &__radioButton {
    flex: 1;
    flex-direction: row-reverse;
    width: 100%;

    .customRadio__label {
      text-align: left;
    }

    .customRadio__checkmark {
      margin: 0 0 0 8px;
    }
  }

  &__title {
    text-align: center;
  }

  &__card {
    align-items: center;
    background-color: $color-base-primary;
    border: 2px solid $color-control-border-primary;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    gap: 16px;
    padding: 24px;
    transition:
      background-color 0.3s,
      border-color 0.3s;
    width: 100%;

    h5 {
      word-wrap: break-word;
    }

    &__selected {
      background-color: $color-control-border-inverted;
      border-color: $color-base-accent;
    }
  }
}

@include mq($from: tablet, $until: desktop) {

  .communicationMethodSelector {

    &__container {
      max-width: 100%;
    }
  }
}

@include mq($until: tablet) {

  .communicationMethodSelector {

    &__container {
      flex-direction: column;
    }

    &__image {
      margin-bottom: 0;
    }
  }
}
