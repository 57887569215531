@use "sass:math"; @import "abstract";
.loanProductsFilter {
  background: white;
  height: fit-content;
  max-width: 420px;
  padding: 40px;
  position: sticky;
  top: 0;
  transition: top 250ms ease-in-out;
  width: 100%;

  &_header_up {
    top: 120px;
  }

  &__title {
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__sumSlider {

    label {
      font-size: 14px;
    }
  }

  &__tabList {
    background: $color-control-bg-secondary;
    border-radius: 44px;
    margin: 0 auto;
    padding: 8px;
    width: fit-content;
  }

  &__tabItem {
    border: 0;
    border-radius: 40px;
    color: $color-text-primary;
    display: flex;
    padding: 14px 24px;

    &:hover {
      border: 0;
      padding: 14px 24px;
    }

    & .pole-ui-tab__text {
      height: auto;
      justify-content: center;
    }

    &.pole-ui-tab_active {
      background: $color-control-bg-accent;
      color: $color-text-inverted;
    }
  }

  & &__tabItem + &__tabItem.pole-ui-tab {
    margin-left: 8px;
  }
}

@include mq($until: desktop) {

  .loanProductsFilter {
    max-width: unset;
    position: static;

    &__tabList {
      width: 100%;
    }

    &__tabItem {
      flex: 1;
      justify-content: center;
    }
  }
}

@include mq($until: tablet) {

  .loanProductsFilter {
    margin: 0 -16px;
    padding: 16px;
    width: auto;

    &__tabItem {
      font-size: 14px;
      padding: 8px 24px;

      &:hover {
        padding: 8px 24px;
      }
    }
  }
}
